.hg-theme-default.hg-layout-numeric .hg-button {
    align-items: center !important;
    display: flex !important;
    justify-content: center !important;
    height: 120px !important;
    font-size: 45px !important;
}

.hg-theme-default.hg-layout-numeric .button-2 {
    width: 187px!important;
}

.hg-theme-default.hg-layout-numeric .button-6 {
    width: 615px!important;
}

.kiosk-scrollbar::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 12px;
}

.kiosk-scrollbar::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.kiosk-toast .Toastify__toast-body {
    font-size: 40px;
}

.Toastify__toast {
    border-radius: 25px;
}

.Toastify__toast-container--center-center {
    width: 800px;
    padding: 15px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 70%;
}

.Toastify__toast-container--top-center {
    width: 800px;
    opacity: 70%;
}

.Toastify__zoom-enter {
    width: 50px;
    margin: 22px;
}

.Toastify__toast-body {
    font-size: 50px;
    font-weight: 500;
    line-height: 1.3;
    word-break: keep-all;
}


.hg-theme-default .hg-button.hg-standardBtn[data-skbtn="@"] {
    max-width: none!important;
}
